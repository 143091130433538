









































































































@import '@design';

.location {
  &--content {
    min-height: 20vw;
  }
  &--info {
    .r-title {
      // TODO (ESS) : don't. even
      line-height: 1.5 !important;

      @media only screen and (max-width: 414px) {
        font-size: 18px !important;
      }
    }
  }
  &--name {
    width: 80%;
    border-bottom: 3px solid $color-bloak-piggy-pink;
    a {
      text-decoration: none;
    }
  }
  &--phone-link {
    text-decoration: none;
  }
  &--hours {
    height: 2.5rem;
    white-space: pre;
  }
}
.loc-image {
  width: 100%;
  height: 12vw;
  min-height: 180px;
  color: white;
  cursor: pointer;
  background-color: $color-bloak-light-blue;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
